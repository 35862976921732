//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MixinTeamOptions from "@/mixins/teamOptions";
export default {
  mixins: [MixinTeamOptions],
  components: {
    XHeader: () => import("@/components/Header"),
    baseView: () => import("@/components/BaseView"),
    // selProject: () => import("@/components/Selectors/selPro"),
    TaskExcel: () => import("@/components/taskExcel"),
    TaskListPage: () => import("./taskListPage"),
    AddPlan: () => import("./addPlan"),
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      functionName: "任务管理",
      url: "/workPlan",
      jId: null,
      initial: true,
      teamValue: null, //选择的团队
      teamOptions: [],
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      },
      activePlan: null, //选择要查任务的计划
      screen: {
        //筛选条件
        proIdsList: [], //项目筛选条件
        viewType: 1,
        searchText: null,
      },
      selEditPlan: null, //选择编辑的计划
      lloading: false, //左边的加载
      rloading: false, //右边的加载
      viewRadio: 0, //看板单选
      listData: [],
      taskdata: [], //任务列表
      isFullScreen: false, //是否全屏
      drawerPlanList: false, //移动端计划列表抽屉
    };
  },
  watch: {
    url(val) {
      this.$router.replace(val);
    },
    isFullScreen(val, oval) {
      this.getTaskData();
      if (val) {
        this.$modal.show("fullScreenExcel");
      }
    },
    teamValue(val, oval) {
      if (oval) {
        this.jId = null;
        var kind = this.viewRadio == 1 ? "card" : "excel";
        if (val) {
          this.refreshUrl(kind, this.teamValue);
        } else {
          this.refreshUrl(kind);
        }
      }
    },
    activePlan(val, oval) {
      if (oval && val.id != oval.id) {
        this.jId = null;
        var kind = this.viewRadio == 1 ? "card" : "excel";
        if (val) {
          this.refreshUrl(kind, this.teamValue, val.id);
        } else {
          if (this.teamValue) {
            this.refreshUrl(kind, this.teamValue);
          } else {
            this.refreshUrl(kind);
          }
        }
      }
    },
    viewRadio(val, oval) {
      var kind = val == 1 ? "card" : "excel";
      if (oval != 0) {
        this.jId = null;
        if (this.teamValue) {
          this.refreshUrl(kind, this.teamValue);
        } else {
          this.refreshUrl(kind);
        }
      }
      if (this.initial) {
        this.initial = false;
      }
      this.pageData.pageIndex = 1;
      this.getTaskData(true);
    },
  },
  computed: {
    taskDetailPrefixCard() {
      if (this.activePlan) {
        return `/workPlan/card/${this.teamValue}/${this.activePlan.id}`;
      } else {
        return "";
      }
    },
    taskDetailPrefixExcel() {
      if (this.activePlan) {
        return `/workPlan/excel/${this.teamValue}/${this.activePlan.id}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    refreshUrl(kind, teamValue, planId) {
      var url = "/workPlan";
      if (kind) {
        url += `/${kind}`;
      }
      if (teamValue) {
        url += `/${teamValue}`;
      }
      if (planId) {
        url += `/${planId}`;
      }
      if (this.jId) {
        url += `/${this.jId}`;
      }
      this.url = url;
    },
    handleGetData() {
      this.getPlanList(true);
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.handleGetData();
      }
    },
    /**
     * 选择某个计划查任务
     */
    handlePlanTask(val) {
      this.activePlan = val;
      this.getTaskData();
    },
    /**
     * 获取计划列表
     */
    getPlanList() {
      this.lloading = true;
      const data = {
        teamId: this.teamValue,
        proIds: this.screen.proIdsList.map((m) => m.proId),
        viewType: this.screen.viewType,
        searchText: this.screen.searchText,
      };
      this.$http
        .post("/Task/TaskPlan/GetTaskPlan.ashx ", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.listData = resp.data;
            if (this.listData && this.listData.length) {
              if (!this.activePlan) {
                this.activePlan = this.listData[0];
              } else {
                this.activePlan = this.listData.find(
                  (m) => m.id == this.activePlan.id
                );
              }
              this.getTaskData();
            } else {
              this.taskdata = [];
            }
          }
        })
        .finally(() => (this.lloading = false));
    },
    /**
     * 个人/项目成员视图切换
     */
    handleCommandViewType(val) {
      this.screen.viewType = val;
      this.activePlan = null;
      this.getPlanList();
    },
    /**
     * 计划列表下拉菜单切换
     */
    handleCommand(val) {
      if (val.type == 1) {
        this.handleEdit(val.item);
      } else {
        this.handleDel(val.item);
      }
    },
    /**
     * 编辑计划
     */
    handleEdit(val) {
      this.selEditPlan = val;
      this.$modal.show("addPlan");
    },
    /**
     * 删除计划
     */
    handleDel(val) {
      this.$confirm("此操作将删除该计划, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/Task/TaskPlan/DelTaskPlan.ashx", {
              Id: val.id,
              teamId: this.teamValue,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                if (this.activePlan.id == val.id) {
                  this.activePlan = null;
                }
                this.getPlanList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 添加计划
     */
    handleAddPlan() {
      this.drawerPlanList = false;
      this.selEditPlan = null;
      this.$modal.show("addPlan");
    },
    /**
     * 获取项目
     */
    getProject(val) {
      this.screen.proIdsList = val;
      this.activePlan = null;
      this.getPlanList();
    },
    /**
     * 获取任务数据
     */
    getTaskData() {
      if (!this.activePlan) {
        return;
      }
      this.rloading = true;
      const data = {
        teamId: this.teamValue,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        planId: this.activePlan.id,
        boardType: this.viewRadio,
      };
      this.$http.post("/Task/GetTaskSearch.ashx#", data).then((resp) => {
        if (resp.res == 0) {
          this.taskdata = resp.data.Data;
          this.pageData.totalNum = resp.data.TotalCount;
          this.rloading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.pageIndex = 1;

      this.getTaskData();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getTaskData();
    },
    /**
     * 清除计划选择
     */
    initActivePlan() {
      this.activePlan = null;
      this.getPlanList();
    },
    /**
     * 关闭弹窗
     */
    closedFullScreen() {
      this.isFullScreen = false;
    },
  },
  mounted() {
    if (this.$route.params.type == "card") {
      this.viewRadio = 1;
    } else {
      this.viewRadio = 2;
    }
    if (this.$route.params.teamId) {
      this.teamValue = Number.parseInt(this.$route.params.teamId);
    }
    if (this.$route.params.planId) {
      this.activePlan = {
        id: Number.parseInt(this.$route.params.planId),
      };
    }
    if (this.$route.params.jId) {
      this.jId = Number.parseInt(this.$route.params.jId);
    }
  },
};
